
<template>
  <div>
    <v-card>
      <v-card-subtitle>Lista </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="filtro.valor"
              label="numero documento"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <SelectAlmacen
              v-model="filtro.almacen"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true" 
              :filtro="{ id_puesto: store.state.puestoSelect.id_puesto} "
            />
          </v-col>

          <v-col cols="12" md="4">
            <BuscarEmpleado @Getdatos="GetdatosEmpleado" :label="`Empleado`" />
          </v-col>
          <v-col cols="12" md="4">
            <SelectEstado
              v-model="filtro.estado"
              :dense="true"
              :outlined="true"
              :add="false"
              :padre="10"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" md="4">
            <DatePicker
              :clearable="true"
              v-model="filtro.fecha_solicitud"
              :dense="true"
              :outlined="true"
              :label="'Fecha Solicitud'"
            />
          </v-col>
          <v-col cols="12" md="4">
            <DatePicker
              :clearable="true"
              v-model="filtro.fecha_creacion"
              :dense="true"
              :outlined="true"
              :label="'Fecha Creacion'"
            />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small @click="cargar()" :loading="cargandoDatos" class="primary"> Buscar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-progress-linear height="1" v-if="cargandoDatos" indeterminate></v-progress-linear>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">ID</th>
                <th class="text-left">Numero Documento</th>
                <th class="text-left">Fechas</th>
                <th class="text-left">Origen</th>
                <th class="text-left">Destino</th>
                <th class="text-left">Empleado</th>
                <th class="text-left">Estado</th>
                <th class="text-left">Accion</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in Lista" :key="index">
                <td>{{ item.id }}</td>
                <td>
                  <small>{{ item.numero_documento }}</small>
                </td>
                <td>
                  <small>Creacion {{ funciones.formatoFecha(item.fecha_crea, 3) }}</small>
                  <br />
                  <small>Solicitud {{ funciones.formatoFecha(item.fecha_solicitud, 1) }}</small>
                </td>
               
                <td>
                  <small>{{ item.puesto_origen_json.nombre }}</small>
                </td>
                <td>
                  <small>{{ item.puesto_destino_json.nombre }}</small>
                  <br />
                  <small>{{ item.almacen_destino_json.descripcion }}</small>
                </td>
                <td>
                  <small>
                   {{ item.empleado_crea_json.nombre }}
                    {{ item.empleado_crea_json.apellido }}</small
                  >
                 
                </td>
                <td>
                  <v-chip x-small :color="colorEstatus(item.estado)">{{ item.estado_json.descripcion }}</v-chip>
                </td>

                <td>
                  <BtnPdfCompra :id="item.id" :tipoBtn="2" />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on" @click="GetVer(item)">
                        <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver</span>
                  </v-tooltip>
                
                  
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small :disabled="!(item.estado == 67 && store.state.puestoSelect.id_puesto == item.id_puesto_destino)" icon v-bind="attrs" v-on="on" @click="GetAnular(item)">
                        <v-icon color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Anular</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
            <tbody></tbody>
          </template>
        </v-simple-table>
        <div class="text-right">
          <span class="pt-4 mr-2">Total Registros: {{ TotalRow }}</span>
          <br />
          <v-pagination :disabled="cargandoDatos" v-model="Pagina" :length="Paginas" :total-visible="7"></v-pagination>
        </div>
      </v-card-text>
      <ModalView @GetTransferir="GetTransferir" @GetAnular="GetAnular" @GetcargarLista="cargar"    ref="ModalViewCompraRef"></ModalView>
    </v-card>
  </div>
</template>
  
<script>
import solicitudServices from '@/api/servicios/inventario/SolicitudServices'
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import BuscarEmpleado from '@/views/sistema/administracion/archivo/empleado/components/BuscarEmpleado.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import DatePicker from '@/components/DatePicker.vue'
import ModalView from './ModalView.vue'
import BtnPdfCompra from './BtnPdf.vue'
import router from '@/router'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiEye,
  mdiSend,
  mdiCheckAll,
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import funciones from '@/funciones/funciones'
import Vue from 'vue'
export default {
  components: {
    SelectAlmacen,
    BuscarEmpleado,
    SelectEstado,
    DatePicker,
    ModalView,
    BtnPdfCompra,
  },
  watch: {
    '$store.state.puestoSelect.id_puesto': function (news, olds) {
      if (news != olds) {
        this.cargarInicio()
      }
    },
  },
  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const Pagina = ref(1)
    const Limit = ref(5)
    const Paginas = ref(1)
    const TotalRow = ref(1)
    const ModalViewCompraRef = ref(null)
    const filtroInit = {
      id_empleado: null,
      almacen: null,
      estado: null,
      id_puesto: null,
      fecha_solicitud: null,
      fecha_creacion: null,
      valor: '',
      id_puesto_otro: null,
    }
    const anulando = ref(false)
    const colorEstatus = item => {
      switch (item) {
        case 67:
          return 'warning'
          break
        case 68:
          return 'success'
          break
        case 69:
          return 'error'
          break
       
        default:
          return 'default'
          break
      }
    }
    const GetdatosProveedor = datos => {
      if (datos) {
        filtro.value.id_proveedor = datos.id
      } else {
        filtro.value.id_proveedor = null
      }
    }

    const GetAnular = item => {
      if (item.estado != 35) {
        anulando.value = true
        Vue.swal({
          icon: 'warning',
          html: '<h3>¿Seguro que quiere anular la solicitud? </h3>' + `<p>${item.numero_documento ?? item.id}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return solicitudServices.anularsolicitud({ id: item.id, id_empleado: store.state.user.id_empleado })
              .then(response => {
                if (response.data.mensaje == 'ANULADA CON EXITO') {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          anulando.value = false
        })
      }
    }
    const GetdatosEmpleado = datos => {
      if (datos) {
        filtro.value.id_empleado = datos.id
      } else {
        filtro.value.id_empleado = null
      }
    }

    const cargandoDatosEditar = ref(false)
    const filtro = ref(JSON.parse(JSON.stringify(filtroInit)))
    
    const  consultarItem  = async (item)=>{
      var resturn = null
     await solicitudServices.solicitudDetalleListarPaginado({
        id: item.id,
      })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            resturn = response.data.datos.datos[0]
          }
        })
        .catch(error => {
          console.log(error)
        })

        return resturn
    }
    
    const GetTransferir = async item => {
      console.log(item) 
      router.push({ name: 'dashboard-puesto-inventario-translado', params: { id_solicitud: item.id } })
    }
    onBeforeMount(() => {
      cargar()
    })
    watch(Pagina, () => {
      cargar()
    })
    const GetVer = item => {
      
      ModalViewCompraRef.value.abrir(item.id)
    }
    const cargarInicio = () => {
      Pagina.value = 1
      Limit.value = 5
      cargar()
    }
    const cargar = () => {
      try {
        cargandoDatos.value = true
        solicitudServices.solicitudListarPaginado({
          ...filtro.value,
          limit: Limit.value,
          pagina: Pagina.value - 1,
          id_puesto: store.state.puestoSelect.id_puesto,
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              Lista.value = response.data.datos.datos
              Paginas.value = response.data.datos.paginado.paginas
              TotalRow.value = response.data.datos.paginado.registros
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiEye,
        mdiSend,
        mdiCheckAll,
      },
      search,
      Lista,
      ListaLoad,
      GetTransferir,
      cargar,
      config,
      cargandoDatos,
      filtro,
      store,
      funciones,
      colorEstatus,
      Pagina,
      Limit,
      Paginas,
      TotalRow,
      cargarInicio,
      GetdatosEmpleado,
      GetdatosProveedor,
      ModalViewCompraRef,
      GetVer,
      GetAnular,
      anulando,
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  