<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Solicitud </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <SelectPuesto
                  :rules="reglas.id_puesto_destino"
                  :label="`Area a solicitar`"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.id_puesto_origen"
                  :ind_principal="true"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <SelectAlmacen
                  :rules="reglas.id_almacen_destino"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.id_almacen_destino" 
              :filtro="{ id_puesto: store.state.puestoSelect.id_puesto} "
                  :label="`Almacen a recibir`"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <DatePicker
                  :rules="reglas.requerido"
                  v-model="datos.fecha_translado"
                  :dense="true"
                  :outlined="true"
                  :label="'Fecha Translado'"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-textarea
                  row="2"
                  :rules="reglas.requerido"
                  v-model="datos.descripcion"
                  class="mt-0"
                  label="Descripcion"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>
    <v-card-subtitle> Detalle </v-card-subtitle>
    <v-divider></v-divider>

    <v-card-text>
      <v-form ref="formDetalle" lazy-validation>
        <v-row class="mb-4 mt-2">
          <v-col cols="12">
            <BuscarProducto
              :verExistencia="funciones.configuracionSelect(18) == 'true'"
              :disabled="datos.id_puesto_origen == null"
              :label="`Producto detalle`"
              @GetProducto="GetProductoDetalle"
              ref="RefProductoDetalle"
              :filtros="{ id_puesto: datos.id_puesto_origen, ind_sin_recetas_produccion: true }"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Producto</th>
              <th class="text-left">Costo</th>
              <th class="text-left" v-if="funciones.configuracionSelect(18) == 'true'">Existencia</th>
              <th class="text-left">Cantidad</th>
              <th class="text-left">-</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in datos.productos" :key="index">
              <td>
                {{ item.producto.id }}
              </td>
              <td>
                <strong> {{ item.producto.nombre }} </strong> <br />
                <small>{{ item.producto.unidad_medida.descripcion }}</small>
              </td>
              <td>$.{{ funciones.formatoNumeric(item.producto.costo) }}</td>
              <td v-if="funciones.configuracionSelect(18) == 'true'">
                {{ funciones.formatoNumeric(item.producto.existencias_global.existencia) }}
              </td>
              <td>
                <v-text-field
                  style="width: 100px"
                  class="mt-1"
                  v-model="item.cantidad"
                  dense
                  outlined
                  @keyup="changeProductoTransformar(item)"
                  hide-details=""
                ></v-text-field>
                <small class="error--text" v-if="isNaN(item.cantidad)">Error debe ser un numero valido</small>
                <small
                  class="error--text"
                  v-else-if="
                    item.producto.existencias_global.existencia < item.cantidad &&
                    funciones.configuracionSelect(18) == 'true'
                  "
                  >La cantidad debe ser menor a la existente</small
                >
              </td>

              <td>
                <v-btn icon color="error" small @click="eliminarProdcuto(item)">
                  <v-icon small>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <small class="error--text" v-if="datos.productos.length == 0">* Debe agregar por lo menos un producto </small>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import DatePicker from '@/components/DatePicker.vue'
import BuscarProducto from '@/views/sistema/inventario/producto/componentes/BuscarProductoExistencia.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectAlmacenJson from '@/views/sistema/inventario/maestro/almacen/componentes/SelectJson.vue'
import funciones from '@/funciones/funciones'
import TooltipInfo from '@/components/TooltipInfo.vue'
import { mdiInformation, mdiDelete, mdiPencil } from '@mdi/js'
import SolicitudServices from '@/api/servicios/inventario/SolicitudServices'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuesto2.vue'
import SelectTipoTranslado from './SelectTipoTranslado.vue'
export default {
  watch: {
    '$store.state.puestoSelect.id_puesto': function () {
      this.limpiar()
    },
    'datos.id_almacen_origen': function () {
      this.cambiarAlmacen()
    },
    'datos.id_tipo_translado': function () {
      this.changeTipoTransferencia()
    },
  },
  components: {
    DatePicker,
    SelectEstado,
    SelectAlmacen,
    BuscarProducto,
    TooltipInfo,
    SelectAlmacenJson,
    SelectTipoTranslado,
    SelectPuesto,
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const formDetalle = ref(null)

    const RefProductoDetalle = ref(null)

    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      id_puesto_destino: [
        v => !!v || v == 0 || 'Es requerido',
        v => v != datos.value.id_puesto_destino || 'No puede ser igual al origen',
      ],
      id_almacen_destino: [
        v => (!!v && v != 0) || 'Es requerido',
        v => v != datos.value.id_almacen_origen || 'No puede ser igual al origen',
      ],
      numerico_requerido: [
        v => !!v || 'Es requerido',
        v => !isNaN(v) || 'No es numero valido',
        v => !(v == Infinity) || 'No es numero valido 2',
      ],
    }
    const datosInit = {
      id: -1,
      id_puesto_origen: null,
      id_almacen_origen: null,
      id_puesto_destino: store.state.puestoSelect.id_puesto,
      id_almacen_destino: null,
      estado: 67,
      fecha_translado: null,
      descripcion: null,
      id_tipo_translado: 2,
      productos: [],
    }
    const itemAddInit = {
      cantidad: '0',
      existencia: '0',
      producto: {},
      ind_conversion: false,
      id_producto_conversion: null,
      unidad_conversion: 0,
      cantidad_conversion: 0,
      costo_conversion: 0,
    }
    const filtrarAlmacenCosto = item => {
      var exis = {}
      exis = item.existencias.find(it => it.id_almacen == datos.value.id_almacen_origen)

      return exis
    }
    const datos = ref(
      JSON.parse(
        JSON.stringify({
          ...datosInit,
          id_puesto_origen: null,
          id_empleado: store.state.user.id_empleado,
          id_puesto_destino: store.state.puestoSelect.id_puesto,
        }),
      ),
    )
    const limpiar = () => {
      datos.value = JSON.parse(
        JSON.stringify({
          ...datosInit,
          id_puesto_origen: null,
          id_empleado: store.state.user.id_empleado,
          id_puesto_destino: store.state.puestoSelect.id_puesto,
        }),
      )
      itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))

      form.value.resetValidation()
    }
    const changeTipoTransferenciaItem = element => {
      if (element.ind_conversion == false) {
        element.cantidad_conversion = null
        element.unidad_conversion = null
        element.productos_ing_select = null
        element.id_producto_conversion = null
      }
    }
    const changeTipoTransferencia = () => {
      if (datos.value.id_tipo_translado == 1) {
        datos.value.id_puesto_destino = datos.value.id_puesto_origen
        datos.value.productos.forEach(element => {
          element.ind_conversion = false
          element.cantidad_conversion = null
          element.unidad_conversion = null
          element.productos_ing_select = null
          element.id_producto_conversion = null
        })
      }
    }
    const changeProductoTransformar = item => {
      var ing = item.producto.productos_ing.find(it => it.producto.id == item.id_producto_conversion)
      item.unidad_conversion = ing.cantidad
      item.cantidad_conversion = ing.cantidad * item.cantidad
      item.productos_ing_select = ing
    }

    var editando = false

    const eliminarProdcuto = item => {
      datos.value.productos = datos.value.productos.filter(ele => ele.producto.id != item.producto.id)
    }
    const itemAdd = ref(JSON.parse(JSON.stringify(itemAddInit)))
    const cargandos = ref(false)
    const GetProductoDetalle = item => {
      console.log('GetProductoDetalle', item)
      if (editando == false) {
        if (item != undefined) {
          itemAdd.value = {
            id: item.id,
            cantidad: '0',
            existencia: '0',
            ind_conversion: false,
            id_producto_conversion: null,
            unidad_conversion: 0,
            cantidad_conversion: 0,
            costo_conversion: 0,
            producto: {
              id: item.id,
              nombre: item.nombre,
              costo: item.costo,
              existencias: item.existencias,
              unidad_medida: item.unidad_medida,
              productos_ing: item.productos_ing,
              existencias_global: item.existencias_global,
            },
          }
          agregarDetalle()
        }
      } else {
        editando = false
      }
    }
    const CargarEditar = item => {
      datos.value = {
        ...item,
        fecha_translado: funciones.formatoFecha(item.fecha_translado, 4),
      }
      goTo(0)
      cambiarAlmacen()
    }
    const validarForm = () => {
      let val = form.value?.validate()
      if (datos.value.productos.length == 0) {
        val = false
      }
      if (funciones.configuracionSelect(18) == 'true') {
        datos.value.productos.forEach(item => {
          if (!(item.cantidad > 0 && item.cantidad <= item.producto.existencias_global.existencia)) {
            val = false
          } else {
            if (datos.value.id_tipo_translado == 1 && item.ind_conversion == true && !(item.cantidad_conversion > 0)) {
              val = false
            }
          }
        })
      }
      return val
    }

    const agregarDetalle = () => {
      if (datos.value.productos.find(item => item.producto.id == itemAdd.value.producto.id) == undefined) {
        if (itemAdd.value.producto.existencias_global.existencia > 0) {
          datos.value.productos.push({ ...itemAdd.value })
          itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))
          RefProductoDetalle.value.limpiar()
          cambiarAlmacen()
        } else {
          store.commit('setAlert', {
            message: 'El producto de tiene existencia',
            type: 'warning',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Ya existe el producto en la factura',
          type: 'warning',
        })
      }
    }

    const cambiarAlmacen = () => {
      datos.value.productos.forEach(element => {
        var exis = null

        exis = element.producto.existencias.find(it => it.id_almacen == datos.value.id_almacen_origen)

        if (exis) {
          console.log('exis', exis.existencia)
          element.existencia = Number(exis.existencia)
        } else {
          element.existencia = 0
        }
      })
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }

    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          SolicitudServices.solicitudActualizar({ ...datos.value })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}`,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: error,
            funcion: 'guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      store,
      GetProductoDetalle,
      RefProductoDetalle,
      itemAdd,
      formDetalle,
      agregarDetalle,
      icons: {
        mdiInformation,
        mdiDelete,
        mdiPencil,
      },
      eliminarProdcuto,
      funciones,
      filtrarAlmacenCosto,
      cambiarAlmacen,
      changeProductoTransformar,
      changeTipoTransferencia,
      changeTipoTransferenciaItem,
    }
  },
}
</script>