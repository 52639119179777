<template>
  <v-card>
    <v-card-title>
      <span>Solicitud</span>
      <v-spacer></v-spacer>
      <v-btn v-if="modal == true" icon @click="cerrarModal()">
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="cargando" class="text-center">
      <h4>Buscando la Solicitud...</h4>
    </v-card-text>
    <v-card-text v-if="!cargando && datos">
      <v-row>
        <v-col lg="8" md="8" sm="12" cols="12">
          <CabeceraEmpresa></CabeceraEmpresa>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <h2 class="pt-12 pb-2">
            #
            <small>{{ datos.numero_documento }} </small>
          </h2>
          <span class="m-0 p-0"> Fecha creacion: {{ FuncionesGenerales.formatoFecha(datos.fecha_crea, 1) }} </span>
          <br />
          <span class="m-0 p-0"> Hora creacion: {{ FuncionesGenerales.formatoFecha(datos.fecha_crea, 2) }} </span>
          <br />
          <span class="m-0 p-0">
            Fecha Solicitud: {{ FuncionesGenerales.formatoFecha(datos.fecha_solicitud, 1) }}
          </span>
          <br />
          <span class="m-0 p-0">
            Empleado: {{ datos.empleado_crea_json.nombre }} {{ datos.empleado_crea_json.apellido }}</span
          >
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-4 mb-2">
        <v-col md="8" cols="12">
          <strong>Origen</strong>
          <br />
          <span class="m-0 p-0"> Puesto : {{ datos.puesto_origen_json.nombre }}</span>
          <br />
          <span >Trasnlado: {{ datos.translado_json.numero_documento }}</span>
        
          <br />
          <span class="m-0 p-0"> Descripcion: {{ datos.descripcion }}</span>
        </v-col>
        <v-col md="4" cols="12">
          <strong>Destino</strong>
          <br />
          <span class="m-0 p-0"> Puesto : {{ datos.puesto_destino_json.nombre }}</span>
          <br />
          <span class="m-0 p-0"> Almacen: {{ datos.almacen_destino_json.descripcion }}</span> <br />
          
          
          <h4 class="m-0 p-0">Estado: {{ datos.estado_json.descripcion }}</h4>
         
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters v-if="datos.estado == 57 && store.state.puestoSelect.id_puesto != datos.id_puesto_origen">
        <v-col cols="0" sm="6"  md="10" ></v-col>
        <v-col  cols="12"  sm="6"  md="2" >
           
          <v-checkbox  class="shrink mr-2 mt-0 " v-model="transformarTodo"
              hide-details   label="Transformar todo"></v-checkbox> 
        
        </v-col>
      </v-row>
      
             
       
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Id</th>
                  <th class="text-left">Producto</th>
                  <th class="text-left">Medida</th>
                  <th class="text-right">Cantidad</th>
                  <th class="text-right" v-if="datos.translado_json.numero_documento" >Transferido</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in datos.productos" :key="i">
                  <td>
                    {{ item.producto.id }}
                    
                  </td>
                  <td>
                    <span>{{ item.producto.nombre }}</span> 
                  </td>
                  <td>
                    {{ item.producto.unidad_medida.descripcion }}
                    
                  </td>
                  <td class="text-right">
                    {{ FuncionesGenerales.formatoNumeric(item.cantidad) }}

                    
                  </td>
                  <td class="text-right" v-if="datos.translado_json.numero_documento" >{{ FuncionesGenerales.formatoNumeric(item.cantidad_transferida)  }}</td>
                 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />
          <v-divider></v-divider>
    
    </v-card-text>
    <v-card-actions v-if="!cargando && datos">
      <v-row>
        <v-col cols="12" class="text-center mt-5">
          <BtnPdfSolicitud :id="id" :tipoBtn="1"></BtnPdfSolicitud>
          <v-btn
            v-if="VerButton == true"
            color="success"
            class="ml-2"
            small
            :disabled="datos.estado != 67 || store.state.puestoSelect.id_puesto == datos.id_puesto_destino"
            @click="GetTransferir(datos)"
          >
            <v-icon left small> {{ icons.mdiCheckAll }} </v-icon>Transladar
          </v-btn>
          <v-btn
            v-if="VerButton == true"
            color="error"
            class="ml-2"
            small
            :disabled="!(datos.estado == 67 && store.state.puestoSelect.id_puesto == datos.id_puesto_destino)"
            @click="GetAnular(datos)"
          >
            <v-icon left small> {{ icons.mdiBlockHelper }} </v-icon>Anular
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import SolicitudServices from '@/api/servicios/inventario/SolicitudServices'
import BtnPdfSolicitud from './BtnPdf.vue'
import store from '@/store'
import { mdiClose, mdiPrinter, mdiPencil, mdiCheck, mdiBlockHelper, mdiFilePdfBox, mdiCheckAll } from '@mdi/js'
import funciones from '@/funciones/funciones'
export default {
  components: {
    CabeceraEmpresa,
    BtnPdfSolicitud,
  },
  props: {
    id: Number,
    modal: Boolean,
    VerButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const mdiArrowRightBottom =
      'M20 16L14.5 21.5L13.08 20.09L16.17 17H10.5C6.91 17 4 14.09 4 10.5V4H6V10.5C6 13 8 15 10.5 15H16.17L13.09 11.91L14.5 10.5L20 16Z'
    const cargando = ref(false)
    const datos = ref (null)
    const transformarTodo = ref(false)
    onBeforeMount(() => {
      cargar()
    })
    watch(props, () => {
      cargar()
    })

    watch(transformarTodo, () => {
      datos.value.productos.forEach(element => {
        element.ind_conversion = transformarTodo.value
        indConversionTransformar(element)
      })
    })

    const cerrarModal = () => {
      context.emit('GetCerrarModal')
    }

    const validarRecibir = () => {
      var val = true
      datos.value.productos.forEach(element => {
        if (element.ind_conversion == true && element.id_producto_conversion == null) {
          val = false
        }
      })
      return val
    }

    const GetRecibir = () => {
      if (validarRecibir()) {
        SolicitudServices.solicitudRecibir({ ...datos.value, id_empleado: store.state.user.id_empleado })
          .then(response => {
            if ((response.data.mensaje = 'GUARDADO CON EXITO')) {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
              cargar()
              GetcargarLista()
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            store.commit('setAlert', {
              message: error,
              type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'GetRecibir',
            })
          })
          .finally(() => {})
      } else {
        store.commit('setAlert', {
          message: 'Verifica los productos a convertir',
          type: 'warning',
        })
      }
    }

    const cargar = () => {
      cargando.value = true
      SolicitudServices.solicitudDetalleListarPaginado({ id: props.id })
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            if (response.data.datos.datos != null && response.data.datos.datos.length > 0) {
              datos.value = response.data.datos.datos[0]
            } else {
              store.commit('setAlert', {
                message: 'No hay resultado en la busqueda',
                type: 'warning',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargar',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const indConversionTransformar = item => {
      if (item.ind_conversion) {
        if (item.producto.productos_ing.length == 1) {
          item.id_producto_conversion = item.producto.productos_ing[0].producto.id
          changeSelectProducto(item)
        } else {
          changeSelectProducto(item)
        }
      }
    }

    const changeSelectProducto = element => {
      if (element.ind_conversion == false) {
        element.cantidad_conversion = null
        element.unidad_conversion = null
        element.productos_ing_select = null
        element.id_producto_conversion = null
      } else {
        var elmen = element.producto.productos_ing.find(item => element.id_producto_conversion == item.producto.id)
        element.cantidad_conversion = elmen.cantidad * element.cantidad
        element.unidad_conversion = elmen.cantidad
        element.productos_ing_select = elmen
      }
    }

    const GetAnular = item => {
      context.emit('GetAnular', item)
    }
    const GetTransferir = item => {
      context.emit('GetTransferir', item)
    }
    const GetPdf = item => {
      context.emit('GetPdf', item)
    }
    const GetFinalizar = item => {
      context.emit('GetFinalizar', item)
    }
    const GetcargarLista = () => {
      context.emit('GetcargarLista')
    }
    return {
      icons: {
        mdiClose,
        mdiPrinter,
        mdiPencil,
        mdiCheck,
        mdiBlockHelper,
        mdiFilePdfBox,
        mdiArrowRightBottom,
        mdiCheckAll,
      },
      datos,
      FuncionesGenerales,
      cerrarModal,
      cargar,
      store,
     cargando,
      GetAnular,
      GetTransferir,
      GetPdf,
      GetFinalizar,
      GetRecibir,
      changeSelectProducto,
      indConversionTransformar,
      transformarTodo,
      GetcargarLista,
    }
  },
}
</script>